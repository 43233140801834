import React from 'react';
import PropTypes from 'prop-types';
import TUTDWrapper from "../FeedbackComponent/TUTDWrapper";

const SuccessComponent = (props) => {
    return (
        <TUTDWrapper component={ SuccessInfo }/>
    );
};

const SuccessInfo = (props) => {
    return (
        <div className="improve">
            We've received your feedback and we'll take it from here.
        </div>
    )
}
SuccessComponent.propTypes = {
    onChange: PropTypes.func
};
export default SuccessComponent;
