import axios from 'axios';

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

const postFeedback = async (rating= 0, applicationName, sourceApplication, comments) => {
    const url = process.env.REACT_APP_ONEGOV_SUBMIT_URL;
    console.log(navigator.userAgent);
    console.log('ggggggggg');
    console.log(process.env.REACT_APP_ONEGOV_SUBMIT_URL);
    console.log(rating);
    console.log(applicationName);
    console.log(sourceApplication);
    console.log(comments);

    return axios.post(
        url,
        {
            rating: rating,
            comments: comments,
            applicationName: applicationName,
            sourceApplication: sourceApplication,
            userAgent: navigator.userAgent
        },
        config
    );
};

const apiServices = {
    postFeedback
};

export default apiServices;
