import React, { useState} from 'react';
import {ContentContainer, Heading, SectionPage} from '@snsw-gel/react';
import PropTypes from 'prop-types';
import {
    ErrorAlert, FeedbackDiv,
    PageTitle,
} from './FeedbackComponent.styled';
import { Breadcrumb } from '@snsw-gel/react';
import NavComponent from './NavComponent'

const TUTDWrapper = (props) => {
    const Component = props.component;
    const [errorAlert, setErrorAlert] = useState(false);

    return (
        <div>
            <PageTitle>
                <Heading data-testid='feedback-headingOne' level={2}
                         style={{marginBottom: '32px'}}>
                    MyServiceNSW Account
                </Heading>
            </PageTitle>
            <NavComponent/>
            <ContentContainer>
                < SectionPage>
                    < Breadcrumb
                        linksList={
                            [
                                {
                                    content: 'Home',
                                    path: 'https://my.service.nsw.gov.au/MyServiceNSW/PersonalDashboard'
                                },
                                {
                                    content: 'Home',
                                    path: ''
                                }
                            ]
                        }
                        style={{marginBottom: '32px'}}
                    />
                    {
                        errorAlert &&
                        <ErrorAlert headingLevel='h3' variant={'error'} title='Something went wrong' role='alert'>
                            <div data-testid='error-alert'>
                                <span>We were unable to submit your feedback, please try again.</span>
                            </div>
                        </ErrorAlert>
                    }
                    <FeedbackDiv>
                        <Heading data-testid='feedback-heading' level={3}
                                 style={{marginTop: '-12px', marginBottom: '32px'}}>
                            Customer feedback
                        </Heading>
                        <div className="welcome">
                            Your feedback is welcome
                        </div>
                        <Component data = {errorAlert} updateParentData={setErrorAlert}/>
                    </FeedbackDiv>
                </SectionPage>
            </ContentContainer>
        </div>
    );
};

TUTDWrapper.propTypes = {
    onChange: PropTypes.func
};
export default TUTDWrapper;
